import axios from "axios";
import Element from 'element-ui'
import {getToken} from "@/utils/auth";
import * as Config from "@/settings";
import { removeToken } from "@/utils/auth";
import router from "@/router";
import store from "@/store";
import Cookies from 'js-cookie'
let request = axios.create({
    baseURL: '/',
    timeout: Config.timeout ,// 请求超时时间
})

//添加响应拦截器
request.interceptors.response.use(response => {
    console.log(response.data)
       if(response.data.code == 200){
        return response.data
    //    }else if(response.data.code === 2102){
    //     console.log(response.data.code)
    //     //  this.$store.dispatch('LogOut').then(() => {
          
    //     store.dispatch("LogOut").then(() => {
    //         // console.log(response.data.code)
    //         router.replace("/login");
    //         Cookies.remove('user');
    //         Cookies.remove('user_level')
    //         Cookies.remove('interface_level')
    //         removeToken(Config.TokenKey);
    //          Element.Message.error({
    //             message: response.data.message || '请重新登录',
    //             duration: 3000, // 持续时间（毫秒）
    //         });
    //       });
    //     return Promise.reject(error)
       }
       else{
        Element.Message.error({
            message: response.data.message || '请求发生错误',
            duration: 3000, // 持续时间（毫秒）
        });
        return Promise.reject(error)
       }
    },
    error => {
        Element.Message.error({
            message: error.message || '请求发生错误',
            duration: 3000, // 持续时间（毫秒）
        });
        // let code = error.response.data.status
        // if (code === 401) {//如果认证失败，则实行注销操作
        //     logout()
        // } 
        return Promise.reject(error)
    })

//请求拦截
request.interceptors.request.use(config => {
        // if (getToken()) {
        //     config.headers['Authorization'] = getToken()
        // }
        // config.headers['Content-Type'] = 'application/json'
        console.log('00'+config)
        return config
    },
    error => {
        // 显示错误消息
    
        Element.Message.error({
            message: error.message || '请求发生错误',
            duration: 3000, // 持续时间（毫秒）
        });
        return Promise.reject(error)
    })

export default request
