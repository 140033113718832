<template>
  <div id="outdoorMap">
    <div class="controls">
      <label class="form-label" style="margin-left: 50px"
        ><span>开始时间:</span></label
      >
      <el-date-picker
        class="el-date-editor"
        v-model="selectedStartDate"
        type="datetime"
        placeholder="选择开始时间"
        style="width: 200px"
        format="yyyy-MM-dd HH:mm:ss"
        @change="handleStartDateChange"
      ></el-date-picker>

      <label class="form-label"><span>结束时间:</span></label>
      <el-date-picker
        v-model="selectedEndDate"
        type="datetime"
        placeholder="选择结束时间"
        style="width: 200px"
        format="yyyy-MM-dd HH:mm:ss"
        @change="handleEndDateChange"
      ></el-date-picker>

      <label class="form-label"><span>IMSI:</span></label>
      <el-select
        class="el-select-custom"
        v-model="selectedIMSI"
        placeholder="选择IMSI"
        style="width: 200px"
        @change="handleIMSIChange"
      >
        <el-option
          v-for="imsi in imsiOptions"
          :key="imsi"
          :label="imsi"
          :value="imsi"
          class="el-option-custom"
        ></el-option>
      </el-select>

      <!-- <label class="form-label"><span>倍速:</span></label>
      <el-select
        v-model="selectedSpeed"
        placeholder="选择倍速"
        style="width: 200px"
        @change="handleSpeedChange"
      >
        <el-option label="1x" value="1"></el-option>
        <el-option label="2x" value="2"></el-option>
        <el-option label="4x" value="3"></el-option>
        <el-option label="8x" value="4"></el-option>
        <!-- 添加更多倍速选项 -->
      <!-- </el-select> -->

      <el-button class="form-button" type="primary" @click="startAnimation"
        ><span>开始</span></el-button
      >

      <el-button class="form-button" type="primary" @click="stopAnimation"
        ><span>停止</span></el-button
      >
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如： 组件， 工具 js， 第三方插件 js， json文件， 图片文件等等）
//例如： import 《组件名称》 from '《组件路径》 ';

import AMapLoader from "@amap/amap-jsapi-loader";
import { queryHistoricalTrack } from "@/api/login";
import { DatePicker, Select, Option } from "element-ui";
import Element from "element-ui";
import { queryImsi } from "@/api/login";
import people from "@/assets/images/people7.png";
import Cookies from "js-cookie";
import dayjs from "dayjs"; // 导入 Day.js
// 设置安全密钥
window._AMapSecurityConfig = {
  securityJsCode: "434a00ddcd3f049d3e8257f4256833a5",
};
export default {
  data() {
    //这里存放数据
    return {
      AMap: null,
      //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
      map: null,
      mouseTool: null,
      marker: null,
      latlngs: [],
      selectedStartDate: "", // 用于存储选择的日期
      selectedEndDate: "", // 用于存储选择的日期
      selectedIMSI: "", // 用于存储选择的IMSI
      selectedSpeed: "1", // 用于存储选择的倍速，默认为1
      imsiOptions: [], // IMSI下拉框选项，根据你的需求添加更多选项
    };
  },
  // 在你的Vue组件中注册Element UI组件
  components: {
    DatePicker,
    Select,
    Option,
  },
  //生命周期 - 创建完成（可以访问当前 this 实例）
  created() {
    console.log("created");
  },
  //生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.initMap();
    this.query(); // 查询IMSI
  },
  //方法集合
  methods: {
    initMap() {
      AMapLoader.load({
        key: "afcf4d9b89b43ff96b869528ccb69d02", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Scale",
          "AMap.HawkEye",
          "AMap.ToolBar",
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.ControlBar",
          "AMap.MouseTool",
          "AMap.DragRoute",
          "AMap.MoveAnimation",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.marker = null;
          this.map = new AMap.Map("outdoorMap", {
            //设置地图容器id
            viewMode: "2D", //  是否为3D地图模式
            zoom: 15, // 初始化地图级别
            center: [114.417062, 30.427073], //中心点坐标  武汉 114.417062, 30.427073
            resizeEnable: true,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    pauseAnimation() {
      this.marker.pauseMove();
    },
    resumeAnimation() {
      this.marker.resumeMove();
    },
    stopAnimation() {
      if (this.marker) {
        this.marker.stopMove();
        this.map.clearMap();
        this.latlngs = []; // Clear the stored trajectory data
        this.marker = null; // Clear the marker object
      }
    },

    startAnimation() {
      //判空处理
      if (!this.checkParameters()) {
        return; // Exit if parameters are invalid
      }

      // 使用 day.js 将原始时间字符串转换成指定格式
      this.selectedStartDate = dayjs(this.selectedStartDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.selectedEndDate = dayjs(this.selectedEndDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      // 输出结果
      console.log(this.selectedStartDate + "," + this.selectedEndDate);

      const username = Cookies.get("user");
      // 发送参数
      const data = {
        username: username,
        imsi: this.selectedIMSI,
        start: this.selectedStartDate,
        end: this.selectedEndDate,
      };
      let speedMultiplier = 1; // 默认速度为1
      if (this.selectedSpeed === "2") {
        speedMultiplier = 2;
      } else if (this.selectedSpeed === "3") {
        speedMultiplier = 4;
      } else if (this.selectedSpeed === "4") {
        speedMultiplier = 8;
      }
      this.queryHistorical(data, speedMultiplier);
      console.log("Sending data:", data);
    },
    queryHistorical(data, speedMultiplier) {
      queryHistoricalTrack(data.username, data.imsi, data.start, data.end)
        .then((res) => {
          console.log("res:", res.data.payload);

          // 转换成新的格式并过滤掉 aMapLo 或 aMapLa 为空的项
          const newPayload = res.data.payload
            .filter((item) => item.aMapLo !== "" && item.aMapLa !== "")
            .map((item) => [item.aMapLo, item.aMapLa]);

          if (newPayload.length === 0) {
            console.warn(
              "All items in the payload have empty 'aMapLo' or 'aMapLa' properties."
            );
            return;
          } else if (newPayload.length < 2) {
            console.warn(
              "The payload does not contain enough items (at least 2 are required)."
            );
            return;
          }

          console.log("Filtered and formatted res:", newPayload);
          this.latlngs = newPayload;
          console.log(this.latlngs);
          this.marker = new AMap.Marker({
            map: this.map,
            position: [114.417062, 30.427073],
            //  position: this.latlngs[0],
            // icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
            icon: people,
            offset: new AMap.Pixel(-13, -26),
            angle: 90,
          });

          // 添加 start and end markers
          const startPoint = new AMap.Marker({
            map: this.map,
            position: this.latlngs[0],
            icon: "https://webapi.amap.com/theme/v1.3/markers/n/start.png",
            offset: new AMap.Pixel(-13, -30),
          });

          const endPoint = new AMap.Marker({
            map: this.map,
            position: this.latlngs[this.latlngs.length - 1],
            icon: "https://webapi.amap.com/theme/v1.3/markers/n/end.png",
            offset: new AMap.Pixel(-13, -30),
          });
          // 绘制轨迹
          var polyline = new AMap.Polyline({
            map: this.map,
            path: this.latlngs,
            showDir: true,
            strokeColor: "#28F", //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 6, //线宽
            // strokeStyle: "solid"  //线样式
          });

          var passedPolyline = new AMap.Polyline({
            map: this.map,
            strokeColor: "#AF5", //线颜色
            strokeWeight: 6, //线宽
          });

          this.marker.on(
            "moving",
            function (e) {
              passedPolyline.setPath(e.passedPath);
              this.map.setCenter(e.target.getPosition(), true);
            }.bind(this)
          );

          this.map.setFitView();

          this.marker.moveAlong(this.latlngs, {
            // 每一段的时长
            duration: 1000, //可根据实际采集时间间隔设置
            // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
            autoRotation: true,
          });
        })
        .catch(() => {});
    },
    checkParameters() {
      if (
        !this.selectedStartDate ||
        !this.selectedEndDate ||
        !this.selectedIMSI ||
        !this.selectedSpeed
      ) {
        Element.Message.error({
          message: "请检查参数是否为空",
          duration: 1000,
        });
        return false; // Return false if any parameter is missing
      }

      const startTime = new Date(this.selectedStartDate);
      const endTime = new Date(this.selectedEndDate);

      if (startTime >= endTime) {
        Element.Message.error({
          message: "请确保结束时间要大于开始时间",
          duration: 1000,
        });
        return false; // Return false if start time is greater than or equal to end time
      }

      return true; // All parameters are valid
    },
    query() {
      const username = Cookies.get("user");
      console.log("99" + username);

      queryImsi(username)
        .then((res) => {
          const newImsi = res.data.data.map((item) => item.imsi);
          // res.data.data.some((item) => {
          //   if (item.status === "1") {
          //     foundStatusOne = true; // 如果找到状态为1的项目，将标志位置为 true
          //     return true; // 退出遍历
          //   }
          // });
          console.log("10" + newImsi);
          this.imsiOptions = newImsi;
        })
        .catch(() => {});
    },

    handleStartDateChange(date) {
      // date 参数包含了所选的日期
      this.selectedStartDate = date;
    },
    handleEndDateChange(date) {
      // date 参数包含了所选的日期
      this.selectedEndDate = date;
    },
    handleIMSIChange(imsi) {
      // imsi 参数包含了所选的IMSI
      this.selectedIMSI = imsi;
    },
    // handleSpeedChange(speed) {
    //   // speed 参数包含了所选的倍速
    //   this.selectedSpeed = speed;
    // },
  },
  destroyed() {
    console.log("destroyed");
  },
  //如果页面有 keep-alive 缓存功能， 这个函数会触发
  activated() {},
};
</script>

<style scoped>
#outdoorMap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0px;
  background-color: #304156;
  height: 60px;
  width: 100%;
  background: transparent;
  z-index: 2;
}

.form-label {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 13px;
  color: rgb(15, 16, 16);
}

.form-label span {
  font-size: 13px; /* Adjust the font size for labels */
  color: #070707; /* Set the font color for labels */
}

.form-button {
  margin-left: 20px;
  font-size: 13px; /* Adjust the font size for buttons */
  color: #ffffff; /* Set the font color for buttons */
}
.el-date-editor .el-input {
  font-size: 13px; /* Adjust the font size for the date picker input */
  color: #ffffff; /* Set the font color for the date picker input */
}

.el-date-editor .el-icon-date {
  color: #ffffff; /* Set the font color for the date picker icon */
  font-size: 13px;
}

.el-select-custom {
  font-size: 13px; /* Adjust the font size for the el-select text */
  color: #ffffff; /* Set the font color for the el-select text */
}

.el-option-custom {
  font-size: 13px; /* Adjust the font size for the el-option text */
  color: #000000; /* Set the font color for the el-option text */
}
.input-item {
  height: 2.2rem;
}

.input-card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  width: 20rem;
  border-width: 0;
  border-radius: 0.4rem;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  position: fixed;
  bottom: 12rem;
  right: 2rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.75rem 1.25rem;
}
</style>
