<template>
  <div>
    <div id="container"></div>
    <div id="eye-icon" @click="toggleInfoBoxVisibility">
      <svg-icon
        slot="prefix"
        :icon-class="openOrCloseInfoBox"
        style="width: 30px; height: 30px"
      >
      </svg-icon>
    </div>
    <div class="info-box" v-show="infoBoxDisplay">
      <div class="info-item">
        <div class="imsi-text">{{ currentMarkerText }}</div>
      </div>

      <div class="info-item-scrollbar">
        <el-scrollbar wrap-class="scrollbar-wrapper">
          <el-card
            class="text"
            v-for="(coordinate, index) in coordinates"
            :key="index"
          >
            {{ coordinate[0] }} : {{ coordinate[1][1] }}, {{ coordinate[1][0] }}
          </el-card>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import UeLogo from "@/assets/images/1.gif";
import TRP from "@/assets/images/station2.svg";
import { queryUe } from "@/api/login";
import Cookies from "js-cookie";
import { queryImsi } from "@/api/login";
export default {
  data() {
    return {
      markersTrp: [
        { lnglat: [114.41666902024464, 30.42671279126144], text: "TRP0" },
        { lnglat: [114.41662278180473, 30.42677280895273], text: "TRP1" },
        { lnglat: [114.4167208973328, 30.426742861007842], text: "TRP2" },
        { lnglat: [114.4166746590184, 30.426802878820173], text: "TRP3" },
        { lnglat: [114.41677276317861, 30.426772927868637], text: "TRP4" },
        { lnglat: [114.41672653330804, 30.426832945777605], text: "TRP5" },
      ],
      markersUe: [],
      map: null,
      currentMarkerText: "460001202238762",
      intervalId: null,
      coordinates: [],
      openOrCloseInfoBox: "",
      infoBoxDisplay: false, // 显示掩藏
    };
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "afcf4d9b89b43ff96b869528ccb69d02",
        version: "2.0",
        plugins: [""],
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            viewMode: "2D",
            zoom: 18,
            center: [114.417062, 30.427073],
          });
          // 加载六个TRP信息
          this.markersTrp.forEach((markerInfo) => {
            this.createMarkerTrpAndText(markerInfo);
          });
          this.query();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // 加载六个TRP点位信息
    createMarkerTrpAndText(markerInfo) {
      const marker = new AMap.Marker({
        position: markerInfo.lnglat,
        map: this.map,
        icon: TRP,
      });

      const text = new AMap.Text({
        text: markerInfo.text,
        position: markerInfo.lnglat,
        offset: new AMap.Pixel(0, -30),
      });

      text.setMap(this.map);
    },
    // 加载 UE信息
    createMarkerUeAndText(markerUeInfo) {
      const marker = new AMap.Marker({
        position: markerUeInfo.lnglat,
        map: this.map,
        icon: UeLogo,
      });

      const text = new AMap.Text({
        text: markerUeInfo.text,
        position: markerUeInfo.lnglat,
        offset: new AMap.Pixel(0, -30),
      });

      text.setMap(this.map);
      setTimeout(() => {
        marker.setMap(null);
        text.setMap(null);
      }, 1500);
    },
    query() {
      const username = Cookies.get("user");
      console.log("99" + username);
      queryImsi(username)
        .then((res) => {
          const newStatus = res.data.data.map((item) => item.status);
          console.log("11" + newStatus);
          newStatus.forEach((status) => {
            console.log("12" + status);
            if (status === "1") {
              console.log("13" + status);
              this.startMarkerAnimation();
              newStatus.break(); // 退出循环
            }
          });
        })
        .catch(() => {});
    },
    // 启动加载UE动画
    startMarkerAnimation() {
      this.queryUe();
      this.intervalId = setInterval(() => {
        this.queryUe();
      }, 2000);
    },
    // 一秒查询一次  UE上报经纬度数据
    queryUe() {
      const username = Cookies.get("user");
      console.log("99" + username);
      queryUe(username)
        .then((res) => {
          res.data.payload.forEach((markerInfo) => {
            //  console.log(new Date(),markerInfo.imsi.substring(11, 15))
            this.currentMarkerText = markerInfo.imsi;
          });

          const newMarkersUe = res.data.payload
            .filter(
              (item) =>
                item.lo !== "" &&
                item.la !== "" &&
                item.name !== "" &&
                item.time !== ""
            )
            .map((item) => ({
              lnglat: [item.lo, item.la],
              text: item.name,
              time: item.time,
            }));

          if (newMarkersUe.length === 0) {
            console.warn(
              "All items in the payload have empty 'lo', 'la', 'name', or 'time' properties."
            );
            return;
          }

          this.markersUe = newMarkersUe;
          const newCoordinate = [
            this.markersUe[0].time,
            this.markersUe[0].lnglat,
          ];
          console.log(new Date(), newCoordinate);
          this.coordinates.push(newCoordinate);

          // 最多显示10个
          if (this.coordinates.length > 10) {
            this.coordinates.shift(); // 移除最早一个
          }
          this.coordinates = [...this.coordinates];

          this.markersUe.forEach((markerUeInfo) => {
            this.createMarkerUeAndText(markerUeInfo);
          });
        })
        .catch((e) => {
          this.markersUe = null;
        });
    },

    toggleInfoBoxVisibility() {
      if (this.infoBoxDisplay == true) {
        this.infoBoxDisplay = false;
        this.openOrCloseInfoBox = "open";
      } else {
        this.infoBoxDisplay = true;
        this.openOrCloseInfoBox = "close";
      }
    },
  },
  computed: {},
  created() {
    this.initMap();
    console.log("mounted");
    this.openOrCloseInfoBox = "open";
  },
  destroyed() {
    console.log("destroyed");
    clearInterval(this.intervalId);
  },
};
</script>

<style>
#container {
  width: 100%;
  height: 100vh;
  margin: 0;
  z-index: 1;
}

#eye-icon {
  position: absolute;
  top: 1%; /* Adjust top percentage as needed */
  right: 1%; /* Adjust right percentage as needed */
  cursor: pointer;
  z-index: 3;
  background: transparent;
}

.info-item {
  height: 2.2rem;
}

.info-item-scrollbar {
  height: 23rem;
}

.imsi-text {
  color: white; /* 设置字体颜色为白色 */
  font-weight: bold; /* 设置字体加粗 */
  font-size: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 2.2rem;
}

.info-box {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.226);
  background-clip: border-box;
  border-radius: 0.25rem;
  width: 22rem;
  border-width: 0;
  border-radius: 0.4rem;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  position: fixed;
  bottom: 12rem;
  right: 1rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  z-index: 2;
  padding: 0.2rem 1.25rem;
}

.scrollbar-wrapper {
  padding: 0px;
  background: transparent;
  justify-content: center;
  align-items: center;
}

.scrollbar-wrapper .el-card {
  font-size: 12px; /* Adjust font size with viewport width unit */
  color: white; /* Set font color to white */
  background: transparent;
  height: 2.2rem;
  margin-bottom: 0.1rem;
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>
