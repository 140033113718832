<template>
  <div>
    <div id="indoorMap"></div>
    <div id="eye-icon" @click="toggleInfoBoxVisibility">
      <svg-icon
        slot="prefix"
        :icon-class="openOrCloseInfoBox"
        style="width: 30px; height: 30px"
      >
      </svg-icon>
    </div>
    <div class="info-box" v-show="infoBoxDisplay">
      <div class="info-item">
        <div class="imsi-text">{{ currentMarkerText }}</div>
      </div>

      <div class="info-item-scrollbar">
        <el-scrollbar wrap-class="scrollbar-wrapper">
          <el-card
            class="text"
            v-for="(coordinate, index) in coordinates"
            :key="index"
          >
            {{ coordinate[0] }} : {{ coordinate[1][1] }}, {{ coordinate[1][0] }}
          </el-card>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import UeLogo from "@/assets/images/0.gif";
import TRP from "@/assets/images/station.svg";
// import Map from "@/assets/images/court.svg";
// import Map from "@/assets/images/5GDW.svg";
// import Map from "@/assets/images/t3.svg";
// import Map from "@/assets/images/newBadMinton.svg";
import Map from "@/assets/images/3.svg";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import L from "leaflet"; // Import Leaflet JavaScript
import { queryUe } from "@/api/login";
import Cookies from "js-cookie";
import { queryImsi } from "@/api/login";
export default {
  data() {
    return {
      indoorMap: null,
      // imgWidth: 692,
      // imgHeight: 450,
      imgWidth: 800,
      imgHeight: 400,
      imageUrl: Map, // Replace with your image path
      markersUe: [],
      mark: [],
      coordinates: [],
      intervalId: null,
      // currentMarkerText: "460001202238762",
      currentMarkerText: "",
      openOrCloseInfoBox: "",
      infoBoxDisplay: false, // 显示掩藏
    };
  },
  created() {},
  mounted() {
    this.createIndoorMap();
    this.openOrCloseInfoBox = "open";
  },

  methods: {
    createIndoorMap() {
      try {
        this.indoorMap = L.map("indoorMap", {
          crs: L.CRS.Simple,
          minZoom: -5,
          maxZoom: 18,
          attributionControl: true,
        }); // 这里的 2 表示缩放级别，根据你的需要进行调整;
        // this.indoorMap.setView([0, 0], 2);

        const bounds = [
          [0, -390],
          // [0, 0],
          [this.imgHeight, this.imgWidth],
        ];
        // var imageUrl = 'http://121.41.99.213:16002/static/5GDW.svg'; // 这里填写你的服务器上图片的URL
        L.imageOverlay(this.imageUrl, bounds).addTo(this.indoorMap);
        L.circle([205.6, 565.2],{//模拟圆心坐标点画圆
          color: 'red',
          radius: 5, 
        }).addTo(this.indoorMap);
        L.circle([160.6, 569.2],{
          color: 'red',
          radius: 5, 
        }).addTo(this.indoorMap);
        L.circle([205.6, 262.2],{
          color: 'red',
          radius: 5, 
        }).addTo(this.indoorMap);
        L.circle([155.6, 242.2],{
          color: 'red',
          radius: 5, 
        }).addTo(this.indoorMap);
        // 多个经纬度点位数组
        const trps = [
          //   { coords: [0, 0], text: "TRP00" },
          // { coords: [0, 916], text: "TRP11" },
          // { coords: [1004, 0], text: "TRP22" },
          // { coords: [1004, 916], text: "TRP33" },

          // { coords: [151, 12], text: "TRP0" },
          // { coords: [351, 12], text: "TRP1" },
          // { coords: [151, 242], text: "TRP2" },
          // { coords: [351, 242], text: "TRP3" },
          // { coords: [151, 412], text: "TRP4" },
          // { coords: [351, 412], text: "TRP5" },

          // { coords: [73.6, 185], text: "TRP0" },
          // { coords: [73.58, 202], text: "TRP1" },
          // { coords: [73.6, 214.87], text: "TRP2" },
          // { coords: [92.79, 185], text: "TRP3" },
          // { coords: [92.79, 202.916], text: "TRP4" },
          // { coords: [92.79, 214.87], text: "TRP5" },
          // 羽毛球馆数据 y x
          { coords: [76.6, 483.2], text: "TRP0" },
          { coords: [76.6, 363.75], text: "TRP1" },
          { coords: [76.6, 183.7], text: "TRP2" },
          { coords: [264.11, 184.08], text: "TRP3" },
          { coords: [264.1, 363.9], text: "TRP4" },
          { coords: [263.72, 484.1], text: "TRP5" },
          // 展厅数据
          // { coords: [221.9,485.15], text: "TRP1" },
          // { coords: [185.19, 484.42], text: "TRP2" },
          // { coords: [178.71, 315.13], text: "TRP3" },
          // { coords: [227.67,331.15], text: "TRP4" },
        ];

        // 创建标记并添加到地图，使用自定义图标
        // for (const markerUeInfo of trps) {
        //   const customIcon = L.icon({
        //     iconUrl: TRP, // Custom icon's path
        //     iconSize: [32, 32], // Custom icon's size
        //     iconAnchor: [16, 16], // Adjust the icon anchor to place it below the icon
        //   });

        //   const marker = L.marker(markerUeInfo.coords, {
        //     icon: customIcon,
        //   }).addTo(this.indoorMap);
        //   marker
        //     .bindTooltip(markerUeInfo.text, {
        //       permanent: true,
        //       direction: "top",
        //       offset: [0, -16], // Adjust the offset to move the tooltip above the icon
        //       opacity: 1,
        //       className: "custom-tooltip",
        //     })
        //     .openTooltip();
        // }

        this.indoorMap.fitBounds(bounds);
        this.query();
      } catch (error) {
        console.error("Error creating indoor map:", error);
      }
    },

    query() {
      const username = Cookies.get("user");
      console.log("99" + username);
      this.indoorMap.setView([201, 225], 0);
      queryImsi(username)
        .then((res) => {
          const newStatus = res.data.data.map((item) => item.status);
          console.log("11" + newStatus);
          newStatus.forEach((status) => {
            console.log("12" + status);
            if (status === "1") {
              console.log("13" + status);
              this.startMarkerAnimation();
              newStatus.break(); // 退出循环
            }
          });
        })
        .catch(() => {});
    },
    // 启动加载UE动画
    startMarkerAnimation() {
      console.log(new Date(), "startMarkerAnimation");
      this.queryUe();
      this.intervalId = setInterval(() => {
        console.log(new Date(), "queryUeBefore");
        this.queryUe();
      }, 2000);
    },
    // 一秒查询一次  UE上报经纬度数据
    queryUe() {
      const username = Cookies.get("user");
      queryUe(username)
        .then((res) => {
          // 判断响应数据中的payload是否存在且非空
          if (
            !res.data ||
            !Array.isArray(res.data.payload) ||
            res.data.payload.length === 0
          ) {
            console.warn(
              "No payload data or empty payload received from the server."
            );
            return;
          }
          console.log("res:", res.data.payload);
          const imsiName = res.data.payload.forEach(item=>{
            this.currentMarkerText = item.imsi;
          })
          const newMarkers = res.data.payload
            .filter(
              (item) =>
                item.x !== "" &&
                item.y !== "" &&
                item.name !== "" &&
                item.time !== ""
            )
            .map((item) => ({
              // coords: [item.y*10+151, item.x*10+12],
              coords: [item.y, item.x],//羽毛球的偏移量
              // coords: [item.y, item.x],//展厅的偏移量
              text: item.name,
              time: item.time,
            }));
          const newMarkersUe = res.data.payload
            .filter(
              (item) =>
                item.x !== "" &&
                item.y !== "" &&
                item.name !== "" &&
                item.time !== ""
            )
            .map((item) => ({
              // coords: [item.y*10+151, item.x*10+12],
              coords: [item.y*10+71, item.x*10+182],//羽毛球的偏移量
              // coords: [Math.abs(item.y*10-248), Math.abs(item.x*10-515)],//展厅的偏移量
              text: item.name,
              time: item.time,
            }));

          if (newMarkersUe.length === 0) {
            console.warn(
              "All items in the payload have empty 'x', 'y', 'name', or 'time' properties."
            );
            return;
          }
          // Assign the newMarkersUe array to this.markersUe
          this.mark = newMarkers;
          const newCoordinate = [
            this.mark[0].time,
            this.mark[0].coords,
          ];
          this.markersUe = newMarkersUe;
          // const newCoordinate = [
          //   this.markersUe[0].time,
          //   this.markersUe[0].coords,
          // ];
          this.coordinates.push(newCoordinate);
          // 最多显示10个
          if (this.coordinates.length > 10) {
            this.coordinates.shift(); // 移除最早一个
          }
          this.coordinates = [...this.coordinates];
          console.log(new Date(), this.markersUe);
          this.markersUe.forEach((markerUeInfo) => {
            this.createMarkerUeAndText(markerUeInfo);
          });
        })
        .catch((e) => {
          console.error(e.error);
          this.markersUe = null;
        });
    },
    // 加载 UE信息
    createMarkerUeAndText(markerUeInfo) {
      const customIcon = L.icon({
        iconUrl: UeLogo, // Custom icon's path
        iconSize: [32, 32], // Custom icon's size
        iconAnchor: [16, 16], // Adjust the icon anchor to place it below the icon
      });

      const marker = L.marker(markerUeInfo.coords, { icon: customIcon }).addTo(
        this.indoorMap
      );
      marker
        .bindTooltip(markerUeInfo.text, {
          permanent: true,
          direction: "top",
          offset: [0, -16], // Adjust the offset to move the tooltip above the icon
          opacity: 1,
          className: "custom-tooltip",
        })
        .openTooltip();
      setTimeout(() => {
        marker.remove();
      }, 1500);
    },
    toggleInfoBoxVisibility() {
      if (this.infoBoxDisplay == true) {
        this.infoBoxDisplay = false;
        this.openOrCloseInfoBox = "open";
      } else {
        this.infoBoxDisplay = true;
        this.openOrCloseInfoBox = "close";
      }
    },
  },

  destroyed() {
    console.log("destroyed");
    clearInterval(this.intervalId);
  },
};
</script>
<style scoped>
/* Add your custom styles here */
#indoorMap {
  width: 100%;
  height: 100vh;
  margin: 0;
  z-index: 1;
}

#eye-icon {
  position: absolute;
  top: 1%; /* Adjust top percentage as needed */
  right: 1%; /* Adjust right percentage as needed */
  cursor: pointer;
  z-index: 3;
  background: transparent;
}

.info-item {
  height: 2.2rem;
}

.info-item-scrollbar {
  height: 23rem;
}

.imsi-text {
  color: white; /* 设置字体颜色为白色 */
  font-weight: bold; /* 设置字体加粗 */
  font-size: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 2.2rem;
}

.info-box {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.226);
  background-clip: border-box;
  border-radius: 0.25rem;
  width: 18rem;
  border-width: 0;
  border-radius: 0.4rem;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  position: fixed;
  bottom: 12rem;
  right: 1rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  z-index: 2;
  padding: 0.2rem 1.25rem;
}

.scrollbar-wrapper {
  padding: 0px;
  background: transparent;
  justify-content: center;
  align-items: center;
}

.scrollbar-wrapper .el-card {
  font-size: 12px; /* Adjust font size with viewport width unit */
  color: white; /* Set font color to white */
  background: transparent;
  height: 2.2rem;
  margin-bottom: 0.1rem;
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>