<template>
  <div class="table-container">
    <el-table
      :data="currentPageData"
      style="width: 100%"
      class="full-width"
    >
      <el-table-column label="IMSI" min-width="50%" align="center">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper" align="center">
            {{ scope.row.imsi }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="25%" align="center">
        <template slot-scope="scope">
          <svg-icon slot="prefix" :icon-class="scope.row.status === '0' ? 'red' : 'green'" class="custom-svg-icon"></svg-icon>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="25%" align="center">
        <template slot-scope="scope">
          <el-button
            :loading="scope.row.loadingStart"
            size="mini"
            type="primary"
            class="action-button"
            @click="handleStart(scope.$index, scope.row)"
          >
            <span v-if="!scope.row.loadingStart">开始</span>
            <span v-else>开始中</span>
          </el-button>
          <el-button
            :loading="scope.row.loadingStop"
            size="mini"
            type="danger"
            class="action-button"
            @click="handleStop(scope.$index, scope.row)"
          >
            <span v-if="!scope.row.loadingStop">停止</span>
            <span v-else>停止中</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :total="tableData.length"
      layout="prev, pager, next"
      class="pagination"
    />
  </div>
</template>

<script>

import Cookies from 'js-cookie'
import {queryImsi} from "@/api/login"
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 8,
      loadingStart : false,
      loadingStop : false
    }
  },
  computed: {
    currentPageData() {
      const start = (this.currentPage - 1) * this.pageSize
      const end = start + this.pageSize
      return this.tableData.slice(start, end)
    }
  },
  mounted() {
    this.query()
    
  },
  methods: {
    
    handleStart(index, row) {
      console.log(index, row.imsi)
       const config = {
            imsi: row.imsi,
            oper: "1",
          }
       this.$set(row, "loadingStart", true); // 设置当前行的loadingStart状态为true
       this.$store.dispatch('SendActOrStopMsgImsi', config).then(res=>{
            console.log('下发成功')
            this.tableData[index].status = '1';
             this.$set(row, "loadingStart", false); 
          }).catch(()=>{
             this.$set(row, "loadingStart", false); 
          })
    },
    handleStop(index, row) {
      console.log(index, row.imsi)
       const config = {
            imsi: row.imsi,
            oper: "0",
          }
       this.$set(row, "loadingStop", true); // 设置当前行的loadingStop状态为true
       this.$store.dispatch('SendActOrStopMsgImsi', config).then(res=>{
            console.log('停止成功')
            this.tableData[index].status = '0';
             this.$set(row, "loadingStop", false); 
          }).catch(()=>{
             this.$set(row, "loadingStop", false); 
          })
    },
     query() {
      const username = Cookies.get('user')
      console.log('99'+username)
    
       queryImsi(username).then(res=>{
        
            this.tableData = res.data.data;
        console.log(JSON.stringify(this.tableData));
          }).catch(()=>{
          })
    },
    addRow() {
      // Create a new row and add it to the tableData array
      const newRow = {
        imsi: '新的IMSI',
        status: '0', // Default status
        loadingStart: false,
        loadingStop: false,
      };
      this.tableData.push(newRow);
    },
    removeRow(index) {
      // Remove a row from the tableData array using the provided index
      this.tableData.splice(index, 1);
    },

  }

}
</script>

<style scoped>
/* Add your custom CSS styling here */
.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.full-width {
  flex-grow: 1;
  overflow: auto;
}

.pagination {
  margin-top: 10px;
  align-self: flex-end;
}

/* Center align name and its value */
.el-table .cell {
  display: flex;
  align-items: center;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* 水平居中文本 */
  height: 100%; /* 确保内容填充整个父容器高度 */
}

/* Adjust the size of the SVG icon using a more specific selector */
.el-table .custom-svg-icon {
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
}

/* Set a fixed width for action buttons */
.action-button {
  width: 80px; /* Adjust the width as needed */
}

</style>
