<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
  <div class="grid-map-animation">
    <div id="map-container">
      <svg id="map" />
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'GridMapAnimation',
  mounted() {
    console.log('44')
    const width = 600
    const height = 400
    const cellSize = 15

    const svg = d3.select('#map')
      .attr('width', width)
      .attr('height', height)

    // ...（原始的for循环和红点坐标等部分）

    for (let y = 0; y <= height; y += cellSize) {
      svg.append('line')
        .attr('x1', 0)
        .attr('y1', height - y) // Adjust y position
        .attr('x2', width)
        .attr('y2', height - y) // Adjust y position
        .attr('class', 'grid-cell')
    }

    for (let x = 0; x <= width; x += cellSize) {
      svg.append('line')
        .attr('x1', x)
        .attr('y1', height) // Adjust y position
        .attr('x2', x)
        .attr('y2', height - height) // Adjust y position
        .attr('class', 'grid-cell')
    }

    const redDotCoordinates = [
      { x: 4 * cellSize, y: 4 * cellSize, label: 'TRP 0' },
      { x: 4 * cellSize, y: (16 + 4) * cellSize, label: 'TRP 1' },
      { x: (12 + 4) * cellSize, y: 4 * cellSize, label: 'TRP 2' },
      { x: (12 + 4) * cellSize, y: (16 + 4) * cellSize, label: 'TRP 3' },
      { x: (24 + 4) * cellSize, y: 4 * cellSize, label: 'TRP 4' },
      { x: (24 + 4) * cellSize, y: (16 + 4) * cellSize, label: 'TRP 5' }
    ]
    redDotCoordinates.forEach((dot, index) => {
      svg.append('circle')
        .attr('class', 'dot')
        .attr('cx', dot.x)
        .attr('cy', height - dot.y)
        .attr('r', 4)
        .attr('fill', '#E74C3C')

      svg.append('text')
        .attr('class', 'dot')
        .attr('x', dot.x + 10)
        .attr('y', height - dot.y - 8)
        .text(dot.label)
        .attr('fill', '#333')
    })

    const zoom = d3.zoom()
      .scaleExtent([0.5, 10])
      .on('zoom', zoomed)

    d3.select('#map-container').call(zoom)

    function zoomed(event) {
      svg.attr('transform', event.transform)
    }

    const redDot = svg.append('circle')
      .attr('class', 'animated-dot')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 6)
      .attr('fill', 'red')
      .style('opacity', 0)

    function moveRedDotToRandomPosition() {
      const randomX = Math.floor(Math.random() * width)
      const randomY = Math.floor(Math.random() * height)

      redDot
        .attr('cx', randomX)
        .attr('cy', height - randomY)
        .style('opacity', 1)

      setTimeout(() => {
        redDot.style('opacity', 0)
      }, 900)
    }

    setInterval(moveRedDotToRandomPosition, 1000)
  }
}
</script>

<style >

 #grid-map-animation {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #f0f0f0;
    }

  /* 更改 map-container 样式，添加 margin 以使其离四个边都有一点距离 */
    #map-container {
      position: relative;
      width: calc(100% - 40px); /* 考虑到左右边距，调整宽度 */
      height: calc(100vh - 40px); /* 考虑到上下边距，调整高度 */
      border: 2px solid #000;
      margin: 0px; /* 添加 margin 以保持与四个边的距离 */
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .grid-cell {
      stroke: #ccc;
      stroke-width: 1px;
    }

    .dot {
      font-size: 12px;
      fill: #333;
    }

    .animated-dot {
      fill: blue;
      transition: opacity 0.5s;
    }
</style>
