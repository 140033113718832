import request from '@/utils/request'

export function login(username, password) {
  return request({
    url: 'lcs/web/login',
    method: 'post',
    data: {
      username,
      password,
      // code,
      // uuid
    }
  })
}

export function getInfo() {
  return request({
    url: 'auth/info',
    method: 'get'
  })
}

export function getCodeImg() {
  return request({
    url: 'auth/code',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: 'lcs/web/logout',
    method: 'get'
  })
}

export function sendActOrStopMsgImsi(imsi,oper) {
  return request({
    url: 'lcs/web/sendActOrStopMsg/imsi',
    method: 'post',
    data: {
      imsi,
      oper,
    }
  })
}

export function sendActOrStopMsgUsername(username,oper) {
  return request({
    url: 'lcs/web/sendActOrStopMsg/username',
    method: 'post',
    data: {
      username,
      oper,
    }
  })
}

export function queryImsi(username) {
  return request({
    url: 'lcs/web/query/imsi',
    method: 'post',
    data: {
      username,
    }
  })
}

export function queryUe(username) {
  return request({
    url: 'lcs/web/query/location',
    method: 'post',
    data: {
      username,
    }
  })
}


export function queryHistoricalTrack(username,imsi,start,end) {
  return request({
    url: 'lcs/web/query/historicalTrack',
    method: 'post',
    data: {
      username,
      imsi,
      start,
      end,
    }
  })
}