import Vue from 'vue'
import Vuex from 'vuex'
import {getToken, removeToken, setToken} from "@/utils/auth";
import {getInfo, logout,login,sendActOrStopMsgImsi,sendActOrStopMsgUsername,queryImsi} from "@/api/login";
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        operation: '',
        token: getToken(),
        user: {},
        roles: [],
    },
    mutations: {
        SET_OP: (state, payload) => {
            state.operation = payload
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_USER: (state, user) => {
            state.user = user
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
    },
    actions: {
        // 登录
        Login({ commit }, userInfo) {
            const rememberMe = userInfo.rememberMe
            return new Promise((resolve, reject) => {
                login(userInfo.username, userInfo.password).then(res => {
                    console.log(res)
                    setToken('qweqweqeq', rememberMe)
                      Cookies.set('user_level', res.data.user_level)
                      Cookies.set('interface_level', res.data.interface_level)
                    // commit('SET_TOKEN', res.token)
                    // setUserInfo(res.user, commit)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息，用作动态路由
        GetInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getInfo().then(res => {
                    setUserInfo(res, commit)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登出
        LogOut({ commit }) {
            return new Promise((resolve, reject) => {
                logout().then(res => {
                    console.log(res)
                    logOut(commit)
                    resolve()
                }).catch(error => {
                    logOut(commit)
                    reject(error)
                })
            })
        },

         // 开始定位 imsi
         SendActOrStopMsgImsi({ commit }, config) {
            return new Promise((resolve, reject) => {
                sendActOrStopMsgImsi(config.imsi, config.oper).then(res => {
                    console.log(res)
                    setToken('qweqweqeq', false)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

          // 开始定位 username
          SendActOrStopMsgUsername({ commit }, config) {
            return new Promise((resolve, reject) => {
                sendActOrStopMsgUsername(config.username, config.oper).then(res => {
                    console.log(res)
                    setToken('qweqweqeq', false)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

         // 根据用户查询当前支持的IMSI
        //  QueryImsi({ commit }, queryConfig) {
        //     return new Promise((resolve, reject) => {
        //         queryImsi(queryConfig.username).then(res => {
        //             // console.log(res)
        //             // setToken('qweqweqeq', false)
        //             commit('SET_tableData', res.data.data)
        //             resolve()
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },
    },
    getters: {
    }

})


const logOut = (commit) => {
    // commit('SET_TOKEN', '')
    // commit('SET_ROLES', [])
    removeToken()
}

const setUserInfo = (user, commit) => {
    // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
    if (user.roles.length === 0) {
        commit('SET_ROLES', ['ROLE_SYSTEM_DEFAULT'])
    } else {
        commit('SET_ROLES', user.roles)
    }
    commit('SET_USER', user.user)
}
