<template>
  <div>
    <div id="locationMap" >
      <div class="controls">
        <label class="form-label" style="margin-left: 50px"
          ><span>开始时间:</span></label
        >
        <el-date-picker
          class="el-date-editor"
          v-model="selectedStartDate"
          type="datetime"
          placeholder="选择开始时间"
          style="width: 200px"
          format="yyyy-MM-dd HH:mm:ss"
          @change="handleStartDateChange"
        ></el-date-picker>

        <label class="form-label"><span>结束时间:</span></label>
        <el-date-picker
          v-model="selectedEndDate"
          type="datetime"
          placeholder="选择结束时间"
          style="width: 200px"
          format="yyyy-MM-dd HH:mm:ss"
          @change="handleEndDateChange"
        ></el-date-picker>

        <label class="form-label"><span>IMSI:</span></label>
        <el-select
          class="el-select-custom"
          v-model="selectedIMSI"
          placeholder="选择IMSI"
          style="width: 200px"
          @change="handleIMSIChange"
        >
          <el-option
            v-for="imsi in imsiOptions"
            :key="imsi"
            :label="imsi"
            :value="imsi"
            class="el-option-custom"
          ></el-option>
        </el-select>

        <!-- <label class="form-label"><span>倍速:</span></label>
        <el-select
          v-model="selectedSpeed"
          placeholder="选择倍速"
          style="width: 200px"
          @change="handleSpeedChange"
        >
          <el-option label="1x" value="1"></el-option>
          <el-option label="2x" value="2"></el-option>
          <el-option label="4x" value="3"></el-option>
          <el-option label="8x" value="4"></el-option>
          <!-- 添加更多倍速选项 -->
        </el-select> -->

        <el-button class="form-button" type="primary" @click="startClick"
          ><span>开始</span></el-button
        >

        <el-button class="form-button" type="primary" @click="stopClick"
          ><span>停止</span></el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import UeLogo from "@/assets/images/0.gif";
import TRP from "@/assets/images/station.svg";
import StartPoint from "@/assets/images/startPoint.png";
import endPoint from "@/assets/images/endPoint.png";
// import Map from "@/assets/images/court.svg";
// import Map from "@/assets/images/5GDW.svg";
// import Map from "@/assets/images/t3.svg";
// import Map from "@/assets/images/newBadMinton.svg";
// import Map from "@/assets/images/32.svg";
import Map from "@/assets/images/3.svg";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import L from "leaflet"; // Import Leaflet JavaScript
import "leaflet-polylinedecorator";
import "leaflet.animatedmarker/src/AnimatedMarker";
import { queryHistoricalTrack } from "@/api/login";
import { DatePicker, Select, Option } from "element-ui";
import Element from "element-ui";
import { queryImsi } from "@/api/login";
import Cookies from "js-cookie";
import dayjs from "dayjs"; // 导入 Day.js
let locationMap = null;
export default {
  data() {
    return {
      imgWidth: 800,
      imgHeight: 400,
      // imgWidth: 692,
      // imgHeight: 450,
      // imgWidth: 458.4,
      // imgHeight: 502.4,
      imageUrl: Map, // Replace with your image path
      intervalId: null,
      animatedMarker: null,
      newLatlngs: null,
      latlngs: null,
      routeLine: null,
      routeLines: null,
      routeLineFirst: null,
      selectedStartDate: "", // 用于存储选择的日期
      selectedEndDate: "", // 用于存储选择的日期
      selectedIMSI: "", // 用于存储选择的IMSI
      selectedSpeed: "1", // 用于存储选择的倍速，默认为1
      imsiOptions: [], // IMSI下拉框选项，根据你的需求添加更多选项
      startMarker: null,
      endMarker: null,
    };
  },

  // 在你的Vue组件中注册Element UI组件
  components: {
    DatePicker,
    Select,
    Option,
  },
  mounted() {
    this.createLocationMap();
    this.query();
  },
  methods: {
    createLocationMap() {
      try {
        locationMap = L.map("locationMap", {
          crs: L.CRS.Simple,
          minZoom: -5,
          attributionControl: false,
        });

        const bounds = [
          // [0, 0],
          [0, -390],
          [this.imgHeight, this.imgWidth],
        ];
        L.imageOverlay(this.imageUrl, bounds).addTo(locationMap);
        // L.circle([75.6, 485.2],{//模拟圆心坐标点画圆
        //   color: 'red',
        //   radius: 1, 
        // }).addTo(locationMap);
        L.circle([205.6, 565.2],{//模拟圆心坐标点画圆
          color: 'red',
          radius: 5, 
        }).addTo(locationMap);
        L.circle([160.6, 569.2],{
          color: 'red',
          radius: 5, 
        }).addTo(locationMap);
        L.circle([205.6, 262.2],{
          color: 'red',
          radius: 5, 
        }).addTo(locationMap);
        L.circle([155.6, 242.2],{
          color: 'red',
          radius: 5, 
        }).addTo(locationMap);
        // locationMap.setView([0, 0], 2);

        // 多个经纬度点位数组
        const trps = [
           // 羽毛球馆数据
          { coords: [76.6, 483.2], text: "TRP0" },
          { coords: [76.6, 363.75], text: "TRP1" },
          { coords: [76.6, 183.7], text: "TRP2" },
          { coords: [264.11, 184.08], text: "TRP3" },
          { coords: [264.1, 363.9], text: "TRP4" },
          { coords: [263.72, 484.1], text: "TRP5" },
          // { coords: [75.6, 485.2], text: "TRP0" },
          // { coords: [75.6, 365.75], text: "TRP1" },
          // { coords: [75.6, 185.7], text: "TRP2" },
          // { coords: [263.11, 186.08], text: "TRP3" },
          // { coords: [263.1, 365.9], text: "TRP4" },
          // { coords: [262.72, 486.1], text: "TRP5" },
           // 展厅数据
          // { coords: [221.9,485.15], text: "TRP1" },
          // { coords: [185.19, 484.42], text: "TRP2" },
          // { coords: [178.71, 315.13], text: "TRP3" },
          // { coords: [227.67,331.15], text: "TRP4" },
          // { coords: [151, 12], text: "TRP0" },
          // { coords: [351, 12], text: "TRP1" },
          // { coords: [151, 242], text: "TRP2" },
          // { coords: [351, 242], text: "TRP3" },
          // { coords: [151, 412], text: "TRP4" },
          // { coords: [351, 412], text: "TRP5" },
        ];
        // 多个经纬度点位数组
        // const trps = [
        //   { coords: [283, 285], text: "TRP0" },
        //   { coords: [283, 419], text: "TRP1" },
        //   { coords: [337, 285], text: "TRP2" },
        //   { coords: [337, 419], text: "TRP3" },
        // ];

        // this.latlngs = [
        //   [20, 20],
        //   [50, 50],
        //   [100, 80],
        //   [120, 100],
        //   [150, 120],
        //   [180, 140],
        //   [200, 180],
        //   [240, 200],
        //   [280, 240],
        //   [300, 280],
        //   [350, 300],
        //   [360, 320],
        //   [380, 350],
        //   [400, 400],
        //   [600, 500],
        //   [800, 700],
        //   [600, 800],
        //   [500, 400],
        //   [300, 200],
        // ];

        // 创建标记并添加到地图，使用自定义图标
        // for (const markerUeInfo of trps) {
        //   const customIcon = L.icon({
        //     iconUrl: TRP, // Custom icon's path
        //     iconSize: [32, 32], // Custom icon's size
        //     iconAnchor: [16, 16], // Adjust the icon anchor to place it below the icon
        //   });

        //   const marker = L.marker(markerUeInfo.coords, {
        //     icon: customIcon,
        //   }).addTo(locationMap);
        //   marker
        //     .bindTooltip(markerUeInfo.text, {
        //       permanent: true,
        //       direction: "top",
        //       offset: [0, -16], // Adjust the offset to move the tooltip above the icon
        //       opacity: 1,
        //       className: "custom-tooltip",
        //     })
        //     .openTooltip();
        // }

        locationMap.fitBounds(bounds);
      } catch (error) {
        console.error("Error creating indoor map:", error);
      }
      locationMap.setView([201, 225], 0);
    },

    startClick() {
      //判空处理
      if (
        !this.selectedStartDate ||
        !this.selectedEndDate ||
        !this.selectedIMSI ||
        !this.selectedSpeed
      ) {
        Element.Message.error({
          message: "请检查参数是否为空",
          duration: 1000, // 持续时间（毫秒）
        });
        return;
      }

      // 开始时间要大于结束时间
      const startTime = new Date(this.selectedStartDate);
      const endTime = new Date(this.selectedEndDate);

      if (startTime >= endTime) {
        Element.Message.error({
          message: "请确保结束时间要大于开始时间",
          duration: 1000, // 持续时间（毫秒）
        });
        return;
      }

      // 使用 day.js 将原始时间字符串转换成指定格式
      this.selectedStartDate = dayjs(this.selectedStartDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.selectedEndDate = dayjs(this.selectedEndDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      // 输出结果
      console.log(this.selectedStartDate + "," + this.selectedEndDate);

      const username = Cookies.get("user");
      // 发送参数
      const data = {
        username: username,
        imsi: this.selectedIMSI,
        start: this.selectedStartDate,
        end: this.selectedEndDate,
      };
      let speedMultiplier = 1; // 默认速度为1
      if (this.selectedSpeed === "2") {
        speedMultiplier = 2;
      } else if (this.selectedSpeed === "3") {
        speedMultiplier = 4;
      } else if (this.selectedSpeed === "4") {
        speedMultiplier = 8;
      }
      this.queryHistorical(data, speedMultiplier);
      console.log("Sending data:", data);
    },
    stopClick() {
      if (this.intervalId != null) {
        clearInterval(this.intervalId); // 停止定时器
      }
      if (this.routeLines != null && this.routeLines.length > 0) {
        this.routeLines.forEach((line) => {
          line.remove();
          line == null;
        });
      }

      if (this.startMarker != null) {
        this.startMarker.remove();
        this.startMarker = null;
      }
      if (this.endMarker != null) {
        this.endMarker.remove();
        this.endMarker = null;
      }
    },

    queryHistorical(data, speedMultiplier) {
      queryHistoricalTrack(data.username, data.imsi, data.start, data.end)
        .then((res) => {
          if (
            !res.data ||
            !Array.isArray(res.data.payload) ||
            res.data.payload.length === 0
          ) {
            console.warn(
              "No payload data or empty payload received from the server."
            );
            return;
          }
          console.log("res:", res.data.payload);

          // 转换成新的格式
          // 转换成新的格式并过滤掉 x 或 y 为空的项
          const newPayload = res.data.payload
            .filter((item) => item.x !== "" && item.y !== "")
            // .map((item) => [Math.abs(item.y*10-248), Math.abs(item.x*10-515)]);
            .map((item) => [item.y*10+71, item.x*10+182]);

          if (newPayload.length === 0) {
            console.warn(
              "All items in the payload have empty 'x' or 'y' properties."
            );
            return;
          } else if (newPayload.length < 2) {
            console.warn(
              "The payload does not contain enough items (at least 2 are required)."
            );
            return;
          }
          console.log(newPayload);
          this.latlngs = newPayload;
          console.log(this.latlngs);
          // 轨迹线
          this.stopClick();
          const result = [];

          this.routeLines = [];
          this.routeLineFirst = L.polyline(this.latlngs, {
            weight: 8, // 修改线条粗线
            color: "gray", // 新增 color 属性来修改线条颜色
          }).addTo(locationMap);

          const startIcon = L.icon({
            iconUrl: StartPoint, // Custom icon's path
            iconSize: [64, 64], // Custom icon's size
            iconAnchor: [32, 64], // Adjust the icon anchor to place it below the icon
          });

          this.startMarker = L.marker(this.latlngs[0], {
            icon: startIcon,
          }).addTo(locationMap);

          const endIcon = L.icon({
            iconUrl: endPoint, // Custom icon's path
            iconSize: [64, 64], // Custom icon's size
            iconAnchor: [32, 64], // Adjust the icon anchor to place it below the icon
          });

          this.endMarker = L.marker(this.latlngs[this.latlngs.length - 1], {
            icon: endIcon,
          }).addTo(locationMap);

          this.routeLines.push(this.routeLineFirst);

          let i = 1;
          const latlngs = this.latlngs;
          const locationMaps = locationMap;

          this.intervalId = setInterval(() => {
            if (i < latlngs.length) {
              const currentLatLng = latlngs[i];
              const previousLatLng = latlngs[i - 1];
              const tempArray = [previousLatLng, currentLatLng];
              this.routeLine = L.polyline(tempArray, {
                weight: 8,
                color: "red",
              }).addTo(locationMap);
              this.routeLines.push(this.routeLine);
              i++;
            } else {
              clearInterval(this.intervalId); // 停止定时器
            }
          }, 1000 / speedMultiplier); // 1000毫秒等于1秒
        })
        .catch(() => {});
    },
    handleStartDateChange(date) {
      // date 参数包含了所选的日期
      this.selectedStartDate = date;
    },
    handleEndDateChange(date) {
      // date 参数包含了所选的日期
      this.selectedEndDate = date;
    },
    handleIMSIChange(imsi) {
      // imsi 参数包含了所选的IMSI
      this.selectedIMSI = imsi;
    },
    // handleSpeedChange(speed) {
    //   // speed 参数包含了所选的倍速
    //   this.selectedSpeed = speed;
    // },
    query() {
      const username = Cookies.get("user");
      console.log("99" + username);

      queryImsi(username)
        .then((res) => {
          const newImsi = res.data.data.map((item) => item.imsi);
          console.log("10" + newImsi);
          this.imsiOptions = newImsi;
        })
        .catch(() => {});
    },
  },
  destroyed() {},
};
</script>
<style scoped>
/* Add your custom styles here */
#locationMap {
  width: 100%;
  height: 100vh;
  margin: 0;
  z-index: 1;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0px;
  background-color: #304156;
  height: 60px;
  width: 100%;
  z-index: 2;
}

.form-label {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 13px;
  color: aliceblue;
}

.form-label span {
  font-size: 13px; /* Adjust the font size for labels */
  color: #ffffff; /* Set the font color for labels */
}

.form-button {
  margin-left: 20px;
  font-size: 13px; /* Adjust the font size for buttons */
  color: #ffffff; /* Set the font color for buttons */
}
.el-date-editor .el-input {
  font-size: 13px; /* Adjust the font size for the date picker input */
  color: #ffffff; /* Set the font color for the date picker input */
}

.el-date-editor .el-icon-date {
  color: #ffffff; /* Set the font color for the date picker icon */
  font-size: 13px;
}

.el-select-custom {
  font-size: 13px; /* Adjust the font size for the el-select text */
  color: #ffffff; /* Set the font color for the el-select text */
}

.el-option-custom {
  font-size: 13px; /* Adjust the font size for the el-option text */
  color: #000000; /* Set the font color for the el-option text */
}

/* Add these styles to represent the start and end markers */
</style>